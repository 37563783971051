<template>
  <div class="page press">
    <div class="section-header-B">
      <h1>Press</h1>
    </div>

    <div class="md-content">
      <div>
        <ListItem
          v-for="item in webData"
          :key="item.id"
          :title="item.title"
          :details="item.details"
          :link="item.link"
        />
      </div>
    </div>
    <LinkPrompter :links="promptLinks" />
  </div>
</template>

<script>
import ListItem from "@/components/ListItem.vue";
import LinkPrompter from "@/components/LinkPrompter.vue";
import pressJSON from "@/page-contents/presscoverages.json";
// import content from "!raw-loader!@/page-contents/shows-and-events.md";

import getData from "@/services/dc-service.js";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Press Coverage`,
      description: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/press`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "Press",
  components: {
    ListItem,
    LinkPrompter,
  },
  data: function () {
    return {
      webData: [],
      promptLinks: [
        {
          title: "Shows & Events",
          description: "Lectures, panels, and appearances in group shows.",
          target: "/shows-and-events",
        },
        {
          title: "Publications & Interviews",
          description: "Writings and interviews by DEAR CLIMATE.",
          target: "/publications-and-interviews",
        },
      ],
    };
  },
  methods: {
    fetchData() {
      console.log("Getting data from CMS.");
      getData("press").then((response) => this.processData(response.data));
    },
    processData(input) {
      const fetchedData = input.data;
      for (let i = 0; i < fetchedData.length; i++) {
        let thisEntry = {};
        thisEntry.id = fetchedData[i].id;
        thisEntry.title = fetchedData[i].attributes.title;
        thisEntry.details = fetchedData[i].attributes.details;
        if (fetchedData[i].attributes.url) {
          thisEntry.link = fetchedData[i].attributes.url;
        } else {
          thisEntry.link = null;
        }
        this.webData.push(thisEntry);
      }
      // console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    // this.fetchData();
    this.processData(pressJSON);
  },
};
</script>

<style lang="scss" scoped></style>
