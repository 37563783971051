<template>
  <div class="list-item">
    <h3>
      <component :is="link ? 'a' : 'span'" :href="link || ''" target="_blank">
        {{ title }}
      </component>
    </h3>
    <div class="details">{{ details }}</div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    title: String,
    details: String,
    link: String,
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }
  .details {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 1.4rem;
    font-weight: normal;
  }
}
</style>
